import { createSlice } from '@reduxjs/toolkit';
// utils
import { updatePartnerApi } from '../../services/register/updatePartner';
import axios from '../../utils/axios';
import { createUser } from '../../services/user/createUser';
import { getPartnerDetailsFromDomain } from '../../services/register/getPartnerDetailsFromDomain';
import { createPartner } from '../../services/register/createPartner';
import { createOrg } from '../../services/register/createOrg';
import { addUserToOrg } from '../../services/register/addUserToOrg';
import { USER_GLOBAL_HOST_API_KEY } from '../../config-global';

const initialState = {
  isLoading: false,
  error: null,
  partners: [], // partners === resellers from api
  initialized: false,
  partnerProfileInitialized: false,
  partnerProfileDetails: {},
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PARTNERS SUCCESS
    getPartnersSuccess(state, action) {
      const resellers = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.partners = resellers;
    },

    // UPDATE PARTNERS STATUS
    updatePartnerStatus(state, action) {
      const reseller = action.payload;
      state.isLoading = false;
      state.initialized = true;
      const updated = [...state.partners];
      const toUpdate = updated.findIndex(
        (resellerItem) => resellerItem.reseller_id === reseller.reseller_id
      );
      updated[toUpdate].status = reseller.status;
      state.partners = updated;
    },
    // UPDATE PARTNERSPROFILEDETAILS STATUS
    updatepartnerProfileDetailsSuccess(state, action) {
      const profileDetails = action.payload;
      state.isLoading = false;
      state.partnerProfileInitialized = true;
      state.partnerProfileDetails = profileDetails;
    },
  },
});

// Actions
export const { getPartnersSuccess } = partnersSlice.actions;

// Reducer
export default partnersSlice.reducer;

export const getAllPartners = (state) => state.partners;

export function getPartners() {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      // const response = await axios.get('/lms_user/api/reseller');
      // const _id = localStorage.getItem('_id');
      const response = await axios({
        method: 'get',
        url: `/lms_user/api/reseller`,
        // headers: {
        //   'x-user-id': `${_id}`,
        // },
      });
      dispatch(partnersSlice.actions.getPartnersSuccess(response.data.resellers));
    } catch (error) {
      dispatch(partnersSlice.actions.hasError(error));
    }
  };
}

export function changeStatus(resellerId) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const response = await axios.get(`/lms_user/api/reseller_status/${resellerId}`);
      dispatch(partnersSlice.actions.updatePartnerStatus(response.data.reseller));
    } catch (error) {
      dispatch(partnersSlice.actions.hasError(error));
    }
  };
}

export function addPartner(data) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      // creating user
      const userFormData = new FormData();
      userFormData.append('full_name', data.userName);
      userFormData.append('email', data.email);
      userFormData.append('country_code', '+91');
      userFormData.append('phone', data.phoneNumber);
      userFormData.append('password', data.password);

      const user = await createUser(userFormData);

      // getting parent details
      const url = new URL(window.location.href);
      const hostUrl = url.origin;
      const parent = await getPartnerDetailsFromDomain(hostUrl);

      // creating partner
      const partnerFormData = new FormData();
      partnerFormData.append('name', data.name);
      partnerFormData.append('email', data.email);
      partnerFormData.append('phone', data.phoneNumber);
      partnerFormData.append('country_code', '+91');
      partnerFormData.append('password', data.password);
      partnerFormData.append('partner_type', data.resellerType);
      partnerFormData.append(
        'parent',
        JSON.parse(localStorage.getItem('profile'))?.reseller_details?.mongo_resellerid
      );
      partnerFormData.append('user', user._id);

      const part = await createPartner(partnerFormData, user._id);

      // creating org
      const orgFormData = new FormData();
      orgFormData.append('name', data.name);
      orgFormData.append('email', data.email);
      orgFormData.append('phone', data.phoneNumber);
      orgFormData.append('country_code', '+91');
      orgFormData.append('partner', part._id);

      const org = await createOrg(orgFormData, user._id);

      // adding user to org
      const obj = {
        user: user._id,
        partner: part._id,
        role: 'admin',
      };
      const rel = await addUserToOrg(obj, org._id);

      const formData = new FormData();
      formData.append('reseller_name', data?.name);
      formData.append('reseller_type', data?.resellerType);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('location', data?.location);
      formData.append('user_name', data?.userName);
      formData.append('password', data?.password);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller`,
        data: formData,
      });
      dispatch(partnersSlice.actions.getPartnersSuccess(response.data.resellers));
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}

export function updatePartner(data, resellerId) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('_method', 'patch');
      formData.append('reseller_name', data?.name);
      formData.append('reseller_type', data?.resellerType);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('location', data?.location);
      formData.append('user_name', data?.userName);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller/${resellerId}`,
        data: formData,
      });
      dispatch(partnersSlice.actions.getPartnersSuccess(response.data.resellers));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function resetPassword(data) {
  return async () => {
    try {
      await axios({
        method: 'post',
        url: `/lms_user/api/direct_password_reset`,
        params: {
          new_password: data?.newPassword,
          confirm_password: data?.confirmPassword,
          user_type: data?.userType,
          reseller_id: data?.resellerId,
          user_id: data?.userId,
        },
      });
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function manageAccount(data) {
  return async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/get-access-token`,
        params: {
          user: data?.userId,
          partner: data?.partnerId,
        },
      });
      const { accessToken } = response.data;

      const res = await axios({
        method: 'get',
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/session-from-token`,
        params: {
          token: accessToken,
          partner: data?.partnerId,
        },
      });
      const newPartnerData = res.data;

      return { newPartnerData };
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}
// export function manageAccount(data) {
//   return async () => {
//     try {
//       const response = await axios({
//         method: 'post',
//         url: `/lms_user/api/direct_manage_account`,
//         params: {
//           user_type: 'reseller',
//           reseller_id: data?.resellerId,
//           user_id: data?.userId,
//         },
//       });
//       const { access_token, refresh_token } = response.data;
//       return { access_token, refresh_token };
//     } catch (error) {
//       throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
//     }
//   };
// }

// UPDATE PARTNER PROFILE DETAILS
export function getPartnersProfileDetails() {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/reseller_profile');
      dispatch(partnersSlice.actions.updatepartnerProfileDetailsSuccess(response.data));
    } catch (error) {
      dispatch(partnersSlice.actions.hasError(error));
    }
  };
}

export function updatepartnerProfileDetails(data, mongoData) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      // creating partner
      const partnerFormData = new FormData();
      partnerFormData.append('name', data.reseller_name);
      partnerFormData.append('email', data.email);
      partnerFormData.append('phone', data.mobile);
      partnerFormData.append('country_code', '+91');
      partnerFormData.append('password', data.password);
      partnerFormData.append('partner_type', data.reseller_type);
      partnerFormData.append('parent', mongoData.parent);
      partnerFormData.append('user', mongoData.users);

      const part = await updatePartnerApi(partnerFormData, mongoData._id);

      const formData = new FormData();
      formData.append('reseller_name', data?.name);
      formData.append('mobile', data?.phoneNumber);
      formData.append('email', data?.email);
      formData.append('location', data?.location);
      formData.append('website', data?.websiteUrl);
      formData.append('about', data?.about);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller_profile`,
        data: formData,
      });
      dispatch(partnersSlice.actions.updatepartnerProfileDetailsSuccess(response.data));
    } catch (error) {
      throw new Error(error?.message);
    }
  };
}
